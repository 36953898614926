import React, { useEffect, useState } from 'react'
import { kaReducer, Table } from 'ka-table'
import { CSVLink } from 'react-csv'
import {
	ActionType,
	DataType as KaDataType,
	SortDirection,
	SortingMode
} from 'ka-table/enums'
import { format, differenceInDays } from 'date-fns'
import {
	hideLoading,
	loadData,
	showLoading,
	updateData
} from 'ka-table/actionCreators'
import {
	CgPlayButtonO,
	CgPlayPauseO,
	CgBlock,
	CgEye,
	CgViewList
} from 'react-icons/cg'
import { ImMagicWand } from 'react-icons/im'
import config from '../../config.json'
import { useReportDispatch, useReportState } from '../../contexts/report'
import styles from './Reports.module.scss'
import 'ka-table/style.scss'
import { toastMessage } from '../../components'
import { StringUtil } from '../../shared'
import {
	cloneDeep,
	escapeRegExp,
	keyBy,
	merge,
	values,
	isNumber,
	isUndefined
} from 'lodash'
import Colors from './Colors'
import Summary from './Summary'

export const DataType = {
	...KaDataType,
	Percentage: 'percentage'
}

const CampaignControlCell = ({ rowData, handleAssessCampaignAds }) => {
	const classes = [styles.campaignControl]

	return (
		<button
			className={classes.join(' ')}
			title="Assess campaign's ads"
			onClick={handleAssessCampaignAds.bind(null, rowData)}
		>
			<ImMagicWand />
		</button>
	)
}

const AdControlCell = ({ rowData, handleToggleAdStatus }) => {
	const classes = [styles.adControl]
	let icon = <CgBlock />

	if (rowData.status === undefined) return null

	if (!['ACTIVE', 'PAUSED'].includes(rowData.status)) {
		classes.push(styles.blocked)
		return (
			<button
				className={classes.join(' ')}
				onClick={alert.bind(
					null,
					`This ad can only be controlled via Meta Ads Manager. Effective status is ${rowData.status}.`
				)}
			>
				{icon}
			</button>
		)
	}

	icon = <CgPlayButtonO />

	if (rowData.status === 'ACTIVE') {
		icon = <CgPlayPauseO />
		classes.push(styles.active)
	}

	return (
		<button
			className={classes.join(' ')}
			onClick={handleToggleAdStatus.bind(null, rowData)}
		>
			{icon}
		</button>
	)
}

const AdThumbnail = ({ rowData }) => {
	if (rowData.thumbnail === undefined) return null

	return (
		<img alt={rowData.name} src={rowData.thumbnail} className="thumbnail" />
	)
}

export const TrafficSourcePerformanceReport = () => {
	const reportState = useReportState()
	const reportDispatch = useReportDispatch()
	const isBetweenAllowedDateRange =
		differenceInDays(
			reportState.data.selectedDateRange?.[1],
			reportState.data.selectedDateRange?.[0]
		) <= config.maxTrafficSourceReportDays
	const canDisplayReport =
		reportState.data.selectedFunnel?.id &&
		reportState.data.selectedDateRange?.[0] &&
		reportState.data.selectedDateRange?.[1] &&
		isBetweenAllowedDateRange
	const tablePropsInit = {
		columns: [
			{
				key: 'item',
				title: 'Item',
				dataType: DataType.String,
				width: 300,
				sortDirection: SortDirection.Ascend
			},
			{ key: ':control', width: 60 },
			{ key: ':thumbnail', width: 72 },
			{
				key: 'cpm',
				width: 75,
				title: 'CPM',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'impressions',
				width: 85,
				title: 'Imp.',
				dataType: DataType.Number,
				transform: value => value
			},
			{
				key: 'clicks',
				width: 75,
				title: 'Clicks',
				dataType: DataType.Number,
				transform: value => value
			},
			{
				key: 'ctr',
				width: 75,
				title: 'CTR',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'cpc',
				width: 75,
				title: 'CPC',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'hookRate',
				width: 75,
				title: 'Hk%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'cpvr3',
				width: 75,
				title: 'C/3s',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'vr25',
				width: 75,
				title: 'VR25%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'vr50',
				width: 75,
				title: 'VR50%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'vr75',
				width: 75,
				title: 'VR75%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'vr100',
				width: 75,
				title: 'VR100%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'cpl',
				width: 85,
				title: 'CPL',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'cpic',
				width: 85,
				title: 'CPIC',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'cpp',
				width: 85,
				title: 'CPP',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'spend',
				width: 85,
				title: 'Spend',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'revenue',
				width: 85,
				title: 'Rev.',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'profit',
				width: 85,
				title: 'Profit',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'roas',
				width: 75,
				title: 'ROAS',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'refund',
				width: 75,
				title: 'Ref$',
				dataType: DataType.Number,
				transform: value => value.toFixed(2)
			},
			{
				key: 'refundPercent',
				width: 75,
				title: 'Ref$%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'refundAmount',
				width: 75,
				title: 'Refs',
				dataType: DataType.Number,
				transform: value => value
			},
			{
				key: 'refundAmountPercent',
				width: 75,
				title: 'Refs%',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'leads',
				width: 75,
				title: 'Ls',
				dataType: DataType.Number,
				transform: value => value
			},
			{
				key: 'ics',
				width: 75,
				title: 'ICs',
				dataType: DataType.Number,
				transform: value => value
			},
			{
				key: 'purchases',
				width: 75,
				title: 'Ps',
				dataType: DataType.Number,
				transform: value => value
			},
			{
				key: 'lCVR',
				width: 75,
				title: 'LCVR',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'icCVR',
				width: 75,
				title: 'ICCVR',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			},
			{
				key: 'pCVR',
				width: 75,
				title: 'PCVR',
				dataType: DataType.Percentage,
				transform: value => value.toFixed(2)
			}
		],
		sortingMode: SortingMode.Single,
		treeGroupsExpanded: [],
		treeGroupKeyField: 'treeGroupId',
		rowKeyField: 'id'
	}
	const [tableProps, changeTableProps] = useState(tablePropsInit)
	const [resultData, setResultData] = useState([])
	const [summaryData, setSummaryData] = useState([])
	const [csvData, setCsvData] = useState([])
	const [hidePausedItems, setHidePausedItems] = useState(false)
	const tableWrapperStyles = [styles.tableWrapper]

	function dispatch(action) {
		changeTableProps(prevState => kaReducer(prevState, action))

		if (action.type === ActionType.LoadData) {
			const result = []
			const csv = []

			dispatch(showLoading())

			reportState.instance
				.getTrafficSourceStats({
					trafficSourceToken: reportState.data.selectedTrafficSourceToken,
					funnelId: reportState.data.selectedFunnel.id,
					accountIdentifier: reportState.data.selectedTrafficSourceAccount,
					dateFrom: format(
						reportState.data.selectedDateRange[0],
						'YYYY-MM-DD 00:00:00'
					),
					dateTo: format(
						reportState.data.selectedDateRange[1],
						'YYYY-MM-DD 23:59:59'
					)
				})
				.then(stats => {
					if (stats?.campaigns?.length) {
						stats.campaigns.forEach(campaign => {
							if (!campaign?.impressions) {
								return
							}

							result.push({
								treeGroupId: null,
								id: campaign.id,
								item: campaign.name,
								itemType: 'campaign',
								...campaign
							})
							campaign.adSets.forEach(adSet => {
								if (!adSet?.impressions) {
									return
								}

								result.push({
									treeGroupId: campaign.id,
									id: adSet.id,
									item: adSet.name,
									itemType: 'adSet',
									campaignId: campaign.id,
									...adSet
								})
								adSet.ads.forEach(ad => {
									if (!ad?.impressions) {
										return
									}

									result.push({
										treeGroupId: adSet.id,
										id: ad.id,
										item: ad.name,
										itemType: 'ad',
										campaignId: campaign.id,
										adSetId: adSet.id,
										type: ad.type,
										...ad,
										lCVR: (ad.lCVR * 100).toFixed(2),
										icCVR: (ad.icCVR * 100).toFixed(2),
										pCVR: (ad.pCVR * 100).toFixed(2)
									})

									csv.push({
										Campaign: campaign.name,
										'Ad Set': adSet.name,
										Ad: ad.name,
										CPM: ad.cpm ? ad.cpm.toFixed(2) : '—',
										'Imp.': ad.impressions,
										Clicks: ad.clicks,
										CTR: ad.ctr ? ad.ctr.toFixed(2) : '—',
										CPC: ad.clicks ? (ad.spend / ad.clicks).toFixed(2) : '—',
										CPL: ad.cpl ? ad.cpl.toFixed(2) : '—',
										CPIC: ad.cpic ? ad.cpic.toFixed(2) : '—',
										CPP: ad.cpp ? ad.cpp.toFixed(2) : '—',
										'Hk%': ad.hookRate ? ad.hookRate.toFixed(2) : '—',
										'C/3s': ad.cpvr3 ? ad.cpvr3.toFixed(2) : '—',
										'VR25%': ad.vr25 ? ad.vr25.toFixed(2) : '—',
										'VR50%': ad.vr50 ? ad.vr50.toFixed(2) : '—',
										'VR75%': ad.vr75 ? ad.vr75.toFixed(2) : '—',
										'VR100%': ad.vr100 ? ad.vr100.toFixed(2) : '—',
										Spend: ad.spend,
										'Rev.': ad.revenue ? ad.revenue.toFixed(2) : '—',
										Profit: ad.profit ? ad.profit.toFixed(2) : '—',
										ROAS: ad.roas ? ad.roas.toFixed(2) : '—',
										Ref$: ad.refund ? ad.refund.toFixed(2) : '—',
										'Ref$%': ad.refundPercent
											? ad.refundPercent.toFixed(2)
											: '—',
										Refs: ad.refundAmount ? ad.refundAmount.toFixed(2) : '—',
										'Refs%': ad.refundAmountPercent
											? ad.refundAmountPercent.toFixed(2)
											: '—',
										Ls: ad.leads,
										ICs: ad.ics,
										Ps: ad.purchases,
										lCVR: (ad.lCVR * 100).toFixed(2),
										icCVR: (ad.icCVR * 100).toFixed(2),
										pCVR: (ad.pCVR * 100).toFixed(2)
									})
								})
							})
						})

						setResultData(result)
						setSummaryData(result)
						setCsvData(csv)
					} else if (stats !== null) {
						toastMessage('No data available')
						setResultData([])
						setCsvData([])
					} else {
						toastMessage('Sorry, something went wrong', 'error')
						setResultData([])
						setCsvData([])
					}
					dispatch(hideLoading())
				})
		} else if (action.type === ActionType.UpdateTreeGroupsExpanded) {
			const result = cloneDeep(resultData)
			const campaignIndex = result.findIndex(
				item =>
					item.itemType === 'campaign' &&
					item.id === action.rowKeyValue &&
					item.isAdInfoLoaded !== true
			)

			if (campaignIndex < 0) {
				return
			}

			const campaign = result[campaignIndex]
			const ads = result.filter(
				item => item.itemType === 'ad' && item.campaignId === campaign.id
			)

			const isMaxCampaign = ads.some(ad => ad.type === 'max')

			result[campaignIndex].isAdInfoLoaded = true

			setResultData(currentResultData =>
				values(merge(keyBy(currentResultData, 'id'), keyBy(result, 'id')))
			)

			reportState.instance
				.getAdsInfo({
					trafficSourceToken: reportState.data.selectedTrafficSourceToken,
					accountIdentifier: reportState.data.selectedTrafficSourceAccount,
					adType: isMaxCampaign ? 'max' : 'default',
					ads: ads
						.map(item => {
							if (item.type === 'max') {
								return item.campaignId
							}

							return item.id
						})
						.join(',')
				})
				.then(adsInfo => {
					adsInfo?.forEach(adInfo => {
						const index = result.findIndex(
							item =>
								(item.id === adInfo.id && item.itemType === 'ad') ||
								(item.id === adInfo.id && item.type === 'max')
						)
						if (index < 0) return
						result[index].thumbnail = adInfo.thumbnail
						result[index].status = adInfo.status
					})

					setResultData(currentResultData =>
						values(merge(keyBy(currentResultData, 'id'), keyBy(result, 'id')))
					)
				})
		}
	}

	function handleAssessCampaignAds(campaign) {
		const result = cloneDeep(resultData)
		const adData = result
			.filter(
				adItem =>
					adItem.status === 'ACTIVE' && adItem.campaignId === campaign.id
			)
			.map(adItem => {
				adItem.cpc = adItem.clicks ? adItem.spend / adItem.clicks : '—'
				adItem.ctr = adItem.clicks ? `${adItem.ctr}%` : '—'
				adItem.lCVR = adItem.leads ? `${adItem.lCVR}%` : '—'
				adItem.icCVR = adItem.ics ? `${adItem.icCVR}%` : '—'
				adItem.pCVR = adItem.purchases ? `${adItem.pCVR}%` : '—'
				adItem.cpl = adItem.cpl ?? '—'
				adItem.cpic = adItem.cpic ?? '—'
				adItem.cpp = adItem.cpp ?? '—'
				adItem.profit = adItem.profit ?? '—'

				delete adItem.item
				delete adItem.itemType
				delete adItem.treeGroupId
				delete adItem.thumbnail
				delete adItem.status
				delete adItem.stats
				delete adItem.adSetId
				delete adItem.campaignId

				return adItem
			})

		dispatch(showLoading())

		reportState.instance
			.assessCampaignAds({
				data: adData,
				trafficSourceToken: reportState.data.selectedTrafficSourceToken
			})
			.then(response => {
				const result = cloneDeep(resultData)

				result[
					result.findIndex(
						item => item.itemType === 'campaign' && item.id === campaign.id
					)
				].areAdsAssessed = true

				response.forEach(assessment => {
					result[
						result.findIndex(
							item => item.itemType === 'ad' && item.id === assessment.adId
						)
					].stats = {
						advice: assessment.advice,
						explanation: assessment.explanation,
						increasePercentage: assessment.increasePercentage
					}
				})

				setResultData(currentResultData =>
					values(merge(keyBy(currentResultData, 'id'), keyBy(result, 'id')))
				)
				dispatch(hideLoading())
			})
	}

	function handleToggleAdStatus(ad) {
		if (
			window.confirm(
				`Are you sure you want to ${
					ad.status === 'ACTIVE' ? 'PAUSE' : 'ACTIVATE'
				} ${ad.item}?`
			)
		) {
			const newAdStatus = ad.status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
			const result = cloneDeep(resultData)

			dispatch(showLoading())

			reportState.instance
				.toggleAdStatus({
					id: ad.type === 'max' ? ad.campaignId : ad.id,
					status: newAdStatus,
					trafficSourceToken: reportState.data.selectedTrafficSourceToken,
					accountIdentifier: reportState.data.selectedTrafficSourceAccount,
					adGroupId: ad.adSetId,
					adType: ad.type ?? 'default'
				})
				.then(response => {
					if (response?.success === true) {
						for (let i = 0; i < result.length; i++) {
							if (result[i].itemType === 'ad' && result[i].id === ad.id) {
								result[i].status = newAdStatus
								break
							}
						}

						setResultData(result)
					} else {
						toastMessage(
							"Something went wrong. Check if ad's campaign/ad set is paused."
						)
					}
					dispatch(hideLoading())
				})
		}
	}

	useEffect(() => {
		if (
			!reportState.data.selectedFunnel?.id ||
			!reportState.data.selectedDateRange?.[0] ||
			!reportState.data.selectedDateRange?.[1]
		) {
			setResultData([])
			setCsvData([])
			return
		}

		if (!isBetweenAllowedDateRange) {
			toastMessage(
				`Sorry, max date range is ${config.maxTrafficSourceReportDays + 1} days`
			)
			return
		}

		dispatch(loadData())
	}, [
		reportState.data.selectedTrafficSourceToken,
		reportState.data.selectedFunnel,
		reportState.data.selectedDateRange,
		reportState.data.selectedTrafficSourceAccount,
		isBetweenAllowedDateRange
	])

	useEffect(() => {
		dispatch(updateData(resultData))
	}, [resultData])

	useEffect(() => {
		if (
			!resultData.length ||
			!reportState.data.selectedFunnel?.id ||
			!reportState.data.selectedDateRange?.[0] ||
			!reportState.data.selectedDateRange?.[1]
		) {
			setResultData([])
			setCsvData([])
		}
	}, [reportState.data])

	useEffect(() => {
		if (
			!resultData.length ||
			!reportState.data.selectedFunnel?.id ||
			!reportState.data.selectedDateRange?.[0] ||
			!reportState.data.selectedDateRange?.[1]
		) {
			return
		}

		const campaignFilterRegex = new RegExp(
			escapeRegExp(
				StringUtil.normalize(reportState.data.campaignFilter?.trim() ?? '')
			),
			'i'
		)

		let result = resultData

		if (hidePausedItems) {
			const ads = resultData.filter(
				item => item.itemType === 'ad' && item.status === 'ACTIVE'
			)
			result = result.filter(
				item =>
					(item.itemType === 'campaign' &&
						ads.map(a => a.campaignId).includes(item.id)) ||
					(item.itemType === 'adSet' &&
						ads.map(a => a.adSetId).includes(item.id)) ||
					(item.itemType === 'ad' && ads.map(a => a.id).includes(item.id))
			)
		}

		result = result.filter(
			entry =>
				entry.treeGroupId !== null ||
				StringUtil.normalize(entry.item)?.match(campaignFilterRegex)
		)

		reportDispatch({
			type: 'update',
			payload: {
				data: {
					filteredCampaignIds: resultData
						.filter(
							entry =>
								entry.treeGroupId === null &&
								StringUtil.normalize(entry.item).match(campaignFilterRegex)
						)
						.map(entry => entry.id)
				}
			}
		})

		setSummaryData(result)
		dispatch(updateData(result))
	}, [reportState.data, resultData, hidePausedItems])

	if (!canDisplayReport) return null

	if ((reportState.data.campaignFilter ?? '').length || hidePausedItems)
		tableWrapperStyles.push(styles.filtered)

	return (
		<div className={tableWrapperStyles.join(' ')}>
			{csvData?.length ? (
				<CSVLink
					style={{ display: 'inline-block' }}
					data={csvData}
					headers={[
						'Campaign',
						'Ad Set',
						'Ad',
						...tableProps.columns
							.filter(c => !!c.title && c.title !== 'Item')
							.map(c => c.title)
					]}
					filename="data.csv"
				>
					<div className={styles.csvButtonWrapper} title="Download CSV">
						<div className={styles.button}>
							<CgViewList />
						</div>
						Download CSV
					</div>
				</CSVLink>
			) : null}
			{resultData?.some(
				item => item.itemType === 'ad' && item.status === 'ACTIVE'
			) ? (
				<a
					onClick={setHidePausedItems.bind(null, !hidePausedItems)}
					style={{ display: 'inline-block' }}
				>
					<div
						className={styles.hideItemsButtonWrapper}
						title="Control item visibility status"
					>
						<div className={styles.button}>
							<CgEye />
						</div>
						{hidePausedItems
							? 'Show all items'
							: 'Hide paused or unknown status items'}
					</div>
				</a>
			) : null}

			<Table
				{...tableProps}
				height="80vh"
				childComponents={{
					noDataRow: {
						content: () => 'No data available'
					},
					summaryCell: {
						content: ({ column }) => (
							<Summary column={column} data={summaryData} />
						),
						elementAttributes: props => {
							if (props.column.key === 'item') {
								return {
									style: {
										...props.column.style,
										position: 'sticky',
										fontWeight: 'bold',
										left: 0
									}
								}
							}

							let styles = {
								...props.column.style,
								textAlign: 'right'
							}

							if (props.column.key === 'profit') {
								styles.color = props.column.color
							}

							return {
								style: styles
							}
						}
					},
					dataRow: {
						elementAttributes: props => {
							if (
								props.rowData.itemType === 'ad' &&
								props.rowData.status === 'ACTIVE' &&
								props.rowData.stats
							) {
								const rowStyles = {}
								let advice = ''

								switch (props.rowData.stats.advice) {
									case 'PAUSE':
										advice = 'Pause.'
										rowStyles.backgroundColor = '#fbe0e4'
										break

									case 'KEEP':
										advice = 'Keep it running.'
										rowStyles.backgroundColor = '#e0f2fb'
										break

									case 'INCREASE_BUDGET':
										advice = `Increase budget by ${props.rowData.stats.increasePercentage}%.`
										rowStyles.backgroundColor = '#eafbe0'
										break

									case 'INCONCLUSIVE':
										advice = 'Inconclusive.'
										rowStyles.backgroundColor = '#d2d2d2'
										break
								}

								return {
									style: rowStyles,
									title: `${advice} ${props.rowData.stats.explanation}`
								}
							} else if (props.rowData.itemType === 'campaign') {
								const rowStyles = { fontWeight: 'bold' }

								if (props.rowData.areAdsAssessed === true) {
									rowStyles.backgroundColor = '#eaebfc'
								}

								return {
									style: rowStyles
								}
							}
						}
					},
					headCell: {
						elementAttributes: props => {
							if (props.column.key === 'item') {
								return {
									style: {
										...props.column.style,
										position: 'sticky',
										left: 0
									}
								}
							}
						}
					},
					cellText: {
						elementAttributes: props => {
							if (props.column.key === 'profit' && props.value !== undefined) {
								let color = Colors.blue

								if (props.value > 0) {
									color = Colors.green
								} else if (props.value < 0) {
									color = Colors.red
								}

								return {
									style: {
										color: color
									}
								}
							}
						},
						content: props => {
							if (
								props.column.key === ':control' &&
								props.rowData.itemType === 'campaign'
							) {
								if (
									!resultData.filter(
										item =>
											item.itemType === 'ad' &&
											item.campaignId === props.rowData.id &&
											item.status === 'ACTIVE'
									)?.length
								) {
									return null
								}

								return (
									<CampaignControlCell
										{...props}
										handleAssessCampaignAds={handleAssessCampaignAds}
									/>
								)
							} else if (
								props.column.key === ':control' &&
								(props.rowData.itemType === 'ad' ||
									props.rowData.type === 'max')
							) {
								return (
									<AdControlCell
										{...props}
										handleToggleAdStatus={handleToggleAdStatus}
									/>
								)
							} else if (
								props.column.key === ':thumbnail' &&
								props.rowData.itemType === 'ad'
							) {
								return <AdThumbnail {...props} />
							} else if (
								[
									'ctr',
									'lCVR',
									'icCVR',
									'pCVR',
									'vr25',
									'vr50',
									'vr75',
									'vr100',
									'hookRate',
									'refundPercent',
									'refundAmountPercent'
								].includes(props.column.key)
							) {
								if (isUndefined(props.value)) {
									return '—'
								}

								if (!isNumber(props.value)) {
									return `${props.value}%`
								}

								return `${props.value.toFixed(2)}%`
							} else if (
								[
									'cpm',
									'cpl',
									'cpic',
									'cpp',
									'spend',
									'profit',
									'revenue',
									'roas',
									'refund',
									'cpvr3'
								].includes(props.column.key)
							) {
								return props.value !== undefined ? props.value.toFixed(2) : '—'
							} else if (
								props.column.key === 'cpc' &&
								props.rowData.clicks !== undefined &&
								props.rowData.spend !== undefined
							) {
								return props.rowData.clicks
									? (props.rowData.spend / props.rowData.clicks).toFixed(2)
									: '—'
							} else if (
								(props.column.key === 'clicks' &&
									props.rowData.clicks === undefined) ||
								(props.column.key === 'leads' &&
									props.rowData.leads === undefined) ||
								(props.column.key === 'ics' &&
									props.rowData.ics === undefined) ||
								(props.column.key === 'purchases' &&
									props.rowData.purchases === undefined) ||
								(props.column.key === 'roas' &&
									props.rowData.roas === undefined) ||
								(props.column.key === 'spend' &&
									props.rowData.spend === undefined) ||
								(props.column.key === 'profit' &&
									props.rowData.profit === undefined) ||
								(props.column.key === 'cpc' &&
									props.rowData.cpc === undefined) ||
								(props.column.key === 'impressions' &&
									props.rowData.impressions === undefined) ||
								(props.column.key === 'ctr' && props.rowData.ctr === undefined)
							) {
								return '—'
							}
						}
					},
					cell: {
						elementAttributes: props => {
							if (props.column.key === 'item') {
								const styles = {
									...props.column.style,
									position: 'sticky',
									left: 0
								}

								if (props.treeArrowElement === undefined) {
									styles.flexDirection = 'column'
									styles.alignItems = 'flex-end'
									styles.justifyContent = 'center'
								}

								return {
									style: styles
								}
							} else if (
								[
									'cpm',
									'ctr',
									'clicks',
									'impressions',
									'spend',
									'profit',
									'cpc',
									'revenue',
									'cpl',
									'cpic',
									'cpp',
									'leads',
									'ics',
									'purchases',
									'roas',
									'lCVR',
									'icCVR',
									'pCVR',
									'cpvr3',
									'vr25',
									'vr50',
									'vr75',
									'vr100',
									'refund',
									'refundPercent',
									'refundAmount',
									'refundAmountPercent',
									'hookRate'
								].includes(props.column.key)
							) {
								return {
									style: { textAlign: 'right' }
								}
							}
						}
					}
				}}
				dispatch={dispatch}
			/>
		</div>
	)
}
